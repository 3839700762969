import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
// Composition API
import { TypeDocEnum } from '@/services/shared/enum';
import { defineComponent, reactive, ref, watch } from 'vue';
import videojs from 'video.js';
import { Constant } from '@/services/shared/constant'; // Định nghĩa data type của component [data] bằng Typescript

var PropskillViewDocumentComponentData = function PropskillViewDocumentComponentData() {
  _classCallCheck(this, PropskillViewDocumentComponentData);
};

var PropskillViewDocumentComponentProps = function PropskillViewDocumentComponentProps() {
  _classCallCheck(this, PropskillViewDocumentComponentProps);
};

export default defineComponent({
  name: 'PropskillViewDocumentComponent',
  components: {},
  props: {
    modelValue: {
      type: Object,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    dialogView: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onCloseDialog'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var data = reactive({
      modelValue: _objectSpread({}, props.modelValue),
      url: props.url,
      dialogView: props.dialogView,
      type: null,
      player: null,
      showVideo: false,
      isLoading: false,
      loadIframe: false
    });
    var videoPlayer = ref(null);

    var loadVideoPlayer = function loadVideoPlayer() {
      videoPlayer.value.innerHTML = "<video\n            id=\"videoPlayer\"\n            class=\"video-js vjs-theme-forest\"\n            preload=\"auto\"\n            playsinline\n            autoplay\n            muted\n            loop\n          ></video>";
      data.player = videojs('#videoPlayer', {
        fill: true,
        controls: true,
        preload: 'auto'
      }, function () {
        data.player.src({
          type: 'video/mp4',
          src: data.url
        });
      });
      var reloadTime = 0;
      data.player.on('error', function () {
        if (reloadTime >= 3) {
          return;
        }

        reloadTime++;
        data.player.addClass('vjs-waiting');
        data.player.error(null);
        setTimeout(function () {
          data.player.load();
        }, 3000);
      });
    };

    var checkIframe = function checkIframe() {
      var ele = document.getElementById('iframe-doc');
      setTimeout(function () {
        if (!data.loadIframe) {
          ele === null || ele === void 0 ? void 0 : ele.setAttribute('src', (ele === null || ele === void 0 ? void 0 : ele.getAttribute('src')) || '');
          checkIframe();
        }
      }, 5000);
    };

    watch(function () {
      return [props.url, props.modelValue, props.dialogView];
    }, function (newValue) {
      var _a;

      data.dialogView = !!newValue[2];
      data.modelValue = !!newValue[1];

      if (newValue[0] && newValue[0] != data.url) {
        data.isLoading = true;
        data.url = newValue[0];
        data.type = null;
        var arrs = data.url.split('.');
        var typeDoc = arrs[arrs.length - 1];

        for (var i = 0; i < Constant.DOCUMENT.length; i++) {
          if ((_a = Constant.DOCUMENT[i].type) === null || _a === void 0 ? void 0 : _a.includes(typeDoc)) {
            data.type = Constant.DOCUMENT[i].key;
            break;
          }
        }

        if (!data.type) {
          data.type = TypeDocEnum.FILE_DOC;
        }

        if (data.type === TypeDocEnum.MEDIA) {
          setTimeout(function () {
            loadVideoPlayer();
            data.isLoading = false;
          }, 100);
        } else if (data.type === TypeDocEnum.IMAGE) {
          setTimeout(function () {
            data.isLoading = false;
          }, 100);
        } else {
          setTimeout(function () {
            data.isLoading = false;
          }, 1000);
          checkIframe();
        }
      }
    });

    var closeDialogView = function closeDialogView() {
      data.dialogView = false;
      data.url = '';
      data.type = null;
      data.loadIframe = false;

      if (data.player) {
        data.player.dispose();
        data.player = null;
      }

      emit('onCloseDialog', false);
    };

    var iframeLoadHelper = function iframeLoadHelper(e) {
      data.loadIframe = true;
      data.isLoading = false;
    };

    return {
      data: data,
      closeDialogView: closeDialogView,
      videoPlayer: videoPlayer,
      TypeDocEnum: TypeDocEnum,
      iframeLoadHelper: iframeLoadHelper
    };
  }
});