import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { useInit } from '@/services/shared/utils';
import UserAvatarComponent from '@/components/features/user-profile/UserAvatarComponent.vue';
import UserStatusComponent from '@/components/features/user-profile/UserStatusComponent.vue';
import { checkHasAuthority } from '@/services/shared/utils';
import { markPhone } from '@/services/shared/utils';
import { markEmail } from '@/services/shared/utils'; // Định nghĩa data type của component [props] bằng Typescript

var UserDetailDialogProps = function UserDetailDialogProps() {
  _classCallCheck(this, UserDetailDialogProps);
}; // Định nghĩa data type của component [data] bằng Typescript


var UserDetailDialogData = function UserDetailDialogData() {
  _classCallCheck(this, UserDetailDialogData);
};

export default defineComponent({
  name: 'UserDetailDialog',
  components: {
    UserAvatarComponent: UserAvatarComponent,
    UserStatusComponent: UserStatusComponent
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
      required: true
    },
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['update:modelValue', 'update:visible'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base,
        userPermission = _useInit.userPermission;

    var data = reactive({
      isLoading: false,
      dataSource: _objectSpread({}, props.modelValue),
      visible: props.visible,
      isHasViewContact: false
    });
    watch(function () {
      return props.modelValue;
    }, function (newValue, oldValue) {
      return data.dataSource = newValue || null;
    });
    watch(function () {
      return props.visible;
    }, function (newValue, oldValue) {
      return data.visible = newValue;
    });

    var handleDialogClosed = function handleDialogClosed() {
      data.visible = false;
      emit('update:visible', data.visible);
    }; // Life cylce


    onMounted(function () {
      var result = checkHasAuthority(['property.ticket.view-full-contact'], userPermission);

      if (result) {
        data.isHasViewContact = true;
      }
    });
    return {
      // Component reactive data
      data: data,
      // Life cycle
      onMounted: onMounted,
      // Functions
      handleDialogClosed: handleDialogClosed,
      checkHasAuthority: checkHasAuthority,
      markPhone: markPhone,
      markEmail: markEmail
    };
  }
});