import _classCallCheck from "/app/node_modules/@babel/runtime/helpers/esm/classCallCheck.js";
// Composition API
import { defineComponent, reactive, watch } from 'vue';
import { useInit, formatDate, encapsulateUrl } from '@/services/shared/utils';
import { getTimeSince } from '@/services/features/group/group.service';
import GroupListItemAsideSkeleton from '@/components/skeleton/group/GroupListItemAsideSkeleton.vue'; // Định nghĩa data type của component [data] bằng Typescript

var GroupSuggestAsideComponentData = function GroupSuggestAsideComponentData() {
  _classCallCheck(this, GroupSuggestAsideComponentData);
}; // Định nghĩa data type của component [props] bằng Typescript


var GroupSuggestAsideComponentProps = function GroupSuggestAsideComponentProps() {
  _classCallCheck(this, GroupSuggestAsideComponentProps);
};

export default defineComponent({
  name: 'GroupSuggestAsideComponent',
  components: {
    GroupListItemAsideSkeleton: GroupListItemAsideSkeleton
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    groupSuggest: {
      type: Array,
      default: null
    },
    isPropskill: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props) {
    // Setup Default
    var _useInit = useInit(),
        base = _useInit.base;

    var data = reactive({
      isLoading: props.isLoading,
      items: props.groupSuggest,
      isPropskill: props.isPropskill
    });
    watch(function () {
      return props.isLoading;
    }, function () {
      return data.isLoading = props.isLoading;
    });
    watch(function () {
      return props.groupSuggest;
    }, function () {
      return data.items = props.groupSuggest;
    }); // Functions

    var goToGroupDetailPage = function goToGroupDetailPage(id) {
      return base.router.push({
        name: 'group-detail',
        params: {
          id: id
        }
      });
    };

    var goToGroupSuggestPage = function goToGroupSuggestPage() {
      return base.router.push({
        name: 'group-suggest'
      });
    };

    return {
      formatDate: formatDate,
      encapsulateUrl: encapsulateUrl,
      // Component reactive data
      data: data,
      // Functions
      getTimeSince: getTimeSince,
      goToGroupDetailPage: goToGroupDetailPage,
      goToGroupSuggestPage: goToGroupSuggestPage
    };
  }
});